/* eslint-disable */ 
import { createStore } from 'vuex';
import axios from 'axios';

//const url = "https://localhost:7123/";
const url = "https://api.backup.interiorhost.com.br/";
export default createStore({
  state: {
    status: '',
    emptyGuid: '00000000-0000-0000-0000-000000000000',
    baseURL: url,
    homologacao: url.includes("localhost"),
    token: JSON.parse(localStorage.getItem('user-token')) || "",
    user: {},
    mensagem: JSON.parse(localStorage.getItem('mensagem'))
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token, user) {
      state.status = 'success';
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
    set_mensagem(state, msgObj) {
      state.mensagem = msgObj;
      localStorage.setItem('mensagem', JSON.stringify(msgObj));
    },
    remove_mensagem(state) {
      state.mensagem = null;
      localStorage.removeItem('mensagem');
    }
  },
  actions: {
    login({ commit }, token) {
      return new Promise((resolve) => {
        localStorage.setItem("user-token", JSON.stringify(token));
        axios.defaults.headers.common["Authorization"] = "bearer " + token.tokenAcesso;
        commit("auth_request");
        commit("auth_success", token);
        resolve();
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('user-token');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    }
  },
  getters: {
    isLoggedIn: state => {
      if (!state.token || !state.token.dataExpiracao) return false;
      const dataDeExpiracao = new Date(state.token.dataExpiracao);
      const dataAtual = new Date();

      if (dataDeExpiracao <= dataAtual) return false;
      return true;
    },
    authStatus: state => state.status,
    getAutenticacao: state => state.token,
    getMensagem: state => state.mensagem,
    emptyGuid: state => state.emptyGuid,
    baseURL: state => state.baseURL,
    homologacao: state => state.homologacao
  },
  modules: {
    pessoa: {
      state: {
        vm: {}
      },
      mutations: {
        set_pessoa(state, obj) {
          state.vm = obj.vm;
        }
      }
    }
  }
});
