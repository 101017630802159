import { createRouter, createWebHashHistory} from 'vue-router';
//import store from '@/store';

const DefaultContainer = () => import('@/containers/DefaultContainer');

const LoginPage = () => import("@/views/BasePages/LoginPage.vue");
const NotFound = () => import("@/views/BasePages/NotFound.vue");
const ForgotPassword = () => import("@/views/BasePages/ForgotPassword.vue");
const Dashboard = () => import("@/views/BasePages/Dashboard.vue");
const Backups = () => import("@/views/Backups/Backups.vue");
const Faturas = () => import("@/views/Faturas/Faturas.vue");

const Clientes = () => import("@/views/Clientes/Clientes.vue");
const NovoClientes = () => import("@/views/Clientes/NovoCliente.vue");

const Usuarios = () => import("@/views/Usuarios/Usuarios.vue");
const NovoUsuarios = () => import("@/views/Usuarios/NovoUsuario.vue");
const TrocarSenha = () => import("@/views/Usuarios/TrocarSenha.vue");



const routes = [
  {
    path: '/',
    component: DefaultContainer,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'backups',
        name: 'Backups',
        component: Backups,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'faturas',
        name: 'Faturas',
        component: Faturas,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: '/clientes',
        meta: { 
          requiresAuth: true
        },
        children: [
          {
            path: "",
            component: Clientes,
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: "editar/:id",
            name: "EditarClientes",
            component: NovoClientes,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "novo",
            name: "NovoCliente",
            component: NovoClientes,
            meta: {
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: '/usuarios',
        meta: { 
          requiresAuth: true
        },
        children: [
          {
            path: "",
            component: Usuarios,
            meta: {
              requiresAuth: true,
            }
          },
          {
            path: "editar/:id",
            name: "Editar",
            component: NovoUsuarios,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "novo",
            name: "Novo",
            component: NovoUsuarios,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "trocar-senha",
            name: "TrocarSenha",
            component: TrocarSenha,
            meta: {
              requiresAuth: true
            }
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/esqueci-senha',
    component: ForgotPassword,
    children: [
      {
        path: ':id',
        name: 'EsqueciSenha',
        component: ForgotPassword
      }
    ]
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;