import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "@/libs/i18n"
import '@coreui/coreui/dist/css/coreui.min.css'
import './assets/styles/global-styles.css' ;
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Notifications from '@kyvg/vue3-notification'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'
import VueTheMask from 'vue-the-mask'

library.add(fas)

const instance = axios.create({
    baseURL: store.getters.baseURL
  });


const app = createApp(App);
app.config.globalProperties.$axios = instance;
const token = JSON.parse(localStorage.getItem('user-token'));

if (token) {
  instance.defaults.headers.common['Authorization'] = `bearer ${token.tokenAcesso}`;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/login');
    } else {
      next();
    }
  } else {
    if (to.path === '/login' && store.getters.isLoggedIn) {
      next('/dashboard');
    } else {
      next();
    }
  }
});

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('vue3-datatable', Vue3Datatable);
app.use(PrimeVue);
app.use(VueTheMask);
app.use(Notifications)
app.use(router);
app.use(store);
app.use(i18n)
app.mount('#app');